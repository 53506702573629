import Vue from 'vue'
import App from './App.vue'
import 'element-ui/lib/theme-chalk/index.css'
import { Table, TableColumn, Radio, Loading } from 'element-ui'
Vue.use(Table)
Vue.use(TableColumn)
Vue.use(Radio)
Vue.use(Loading.directive);
Vue.prototype.$loading = Loading.service;

Vue.config.productionTip = false

new Vue({
  render: h => h(App)
}).$mount('#app')
